<template>
  <v-app>
      <Ribbon2/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Ribbon2 from '@/components/Ribbon2.vue'

export default {
  name: 'Ribbon2View',
  components: {
    Ribbon2
  }
}
</script>

